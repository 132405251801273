var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","fields":_vm.fields,"items":_vm.items,"busy":_vm.isBusy,"show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"head(id)",fn:function(){return [_c('b-form-checkbox',{attrs:{"id":'checkbox-staff-all',"name":'checkbox-staff--all',"value":true,"unchecked-value":false},on:{"change":function($event){return _vm.$emit('handleCheckAll', $event)}}})]},proxy:true},{key:"cell(id)",fn:function({ item }){return [_c('b-form-checkbox',{attrs:{"id":'checkbox-staff-' + item.id,"name":'checkbox-staff-' + item.id,"value":true,"unchecked-value":false},model:{value:(item.isChecked),callback:function ($$v) {_vm.$set(item, "isChecked", $$v)},expression:"item.isChecked"}})]}},{key:"cell(name)",fn:function({ item }){return [(item.is_main)?_c('font-awesome-icon',{staticClass:"mr-2 text-success",attrs:{"icon":"check"}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.name)+" ")])]}},{key:"cell(first_name)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.stringName(item))+" ")])]}},{key:"cell(total_price)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("numeral")(item.total_price,"0,0"))+" ")])]}},{key:"cell(available)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.available.toLocaleString())+" ")])]}},{key:"cell(active)",fn:function({ item }){return [_c('span',{class:item.active ? 'text-success' : 'text-error'},[_vm._v(_vm._s(item.active ? "Active" : "Inactive"))])]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-content-evenly"},[_c('router-link',{attrs:{"to":'branch-management/inventory/' +
                item.id +
                `?${_vm.encodeParam(`name=${item.name}`)}`}},[_c('font-awesome-icon',{staticClass:"color-primary",attrs:{"icon":"boxes","title":"Inventory"}})],1),_c('router-link',{attrs:{"to":'branch-management/stock/' +
                item.id +
                `?${_vm.encodeParam(`name=${item.name}`)}`}},[_c('font-awesome-icon',{staticClass:"color-primary",attrs:{"icon":"warehouse","title":"Inventory"}})],1),_c('router-link',{attrs:{"to":'branch-management/detail/' + item.id}},[_c('font-awesome-icon',{staticClass:"text-warning",attrs:{"icon":"pencil-alt","title":"Edit"}})],1),_c('router-link',{attrs:{"to":'branch-management/inventory/log/' +
                item.id +
                `?${_vm.encodeParam(`name=${item.name}`)}`}},[_c('font-awesome-icon',{staticClass:"color-primary",attrs:{"icon":"sticky-note","title":"Inventory Log"}})],1)],1)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-black my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-2"},[_vm._v("Loading...")])],1)]},proxy:true}])})],1)],1),_c('Pagination',{attrs:{"pageOptions":_vm.pageOptions,"filter":_vm.filter,"rows":_vm.rows},on:{"handleChangeTake":_vm.handleChangeTake,"pagination":_vm.pagination}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }